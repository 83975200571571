import styled from 'styled-components'

export const AboutContainer = styled.div`
  display: flex;
  margin-top: 5.9375em;
  @media(max-width: 1023px) {
    flex-direction: column;
  }
`

export const AboutContent = styled.div`
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
  max-width: 55%;
  flex: 1 1 auto;
  @media(max-width: 1023px) {
    max-width: 100%;
    text-align: center;
  }
`

export const AboutImageContainer = styled.div`
  flex: 1 1 auto;
  max-width: 45%;
  @media(max-width: 1023px) {
    max-width: 100%;
    max-height: 300px;
  }
`
