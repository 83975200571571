import styled from 'styled-components'

export const MediaContainer = styled.div`
  display: flex;
  margin-top: 5.9375em;
  flex-direction: column;
  padding: 0em 4.625em;
  align-items: center;
  background: white;
`

export const Glide = styled.div.attrs(() => ({
  className: "glide"
}))``

export const GlideTrack = styled.div.attrs(() => ({
  className: "glide__track",
  "data-glide-el": "track"
}))``

export const GlideSlides = styled.ul.attrs(() => ({
  className: "glide__slides"
}))``

export const GlideSlide = styled.li.attrs(() => ({
  className: "glide__slide"
}))`
  display: flex;
  justify-content: center;
  height: auto;
  flex: 1 0 auto;
`

export const GlideArrows = styled.div.attrs(() => ({
  className: "glide__arrows",
  "data-glide-el": "controls"
}))``

export const GlideArrowLeft = styled.button.attrs(() => ({
  className: "glide__arrow glide__arrow--left",
  "data-glide-dir": "<"
}))`
  font-size: 20px;
  border-radius: 999px;
  width: 40px;
  height: 40px;
  left: -34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  text-transform: none;
  border: 3px solid ${({ theme }) => theme.colors.primary.black};
  color: ${({ theme }) => theme.colors.primary.black};
`

export const GlideArrowRight = styled.button.attrs(() => ({
  className: "glide__arrow glide__arrow--right",
  "data-glide-dir": ">"
}))`
  font-size: 20px;
  border-radius: 999px;
  width: 40px;
  height: 40px;
  right: -34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  text-transform: none;
  border: 3px solid ${({ theme }) => theme.colors.primary.black};
  color: ${({ theme }) => theme.colors.primary.black};
`

export const MediaItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 338px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
  position: relative;
  padding-bottom: 3em;
  margin-bottom: 2em;
  background: white;
  & img {
    width: 100%;
    height: 214px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    object-position: center;
  }
  & p {
    padding: 0px 19px;
  }
  & p:last-of-type {
    margin-top: auto;
  }
  @media(max-width: 500px) {
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    & * {
      max-width: 100%;
    }
  }
`

export const MediaItemButtonContainer = styled.div`
  position: absolute;
  bottom: -1.3em;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
`
