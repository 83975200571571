import styled from 'styled-components'
import { Link } from 'gatsby'

export const NavigationContainer = styled.div`
  width: 100%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.09);
  padding: 0.5em 3.75em;
  display: flex;
  box-sizing: border-box;
`

export const NavigationLogoContainer = styled.div`
  max-width: 250px;
`

export const NavigationLinksContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
`

export const NavigationLink = styled(Link)`
  font-family: 'Lato';
  font-size: 1.1875em;
  text-decoration: none;
  color: ${({ active, theme }) =>
    active ? theme.colors.primary.blue : theme.colors.primary.black};
  border-top: ${({ active, theme }) =>
    active && `3px solid ${theme.colors.primary.blue}`};
  font-weight: ${({ active }) => active && 800};
  padding: 0.35em 0.4em;
  &:not(:last-child) {
    margin-right: 1.25em;
  }
`

export const NavigationMobileLink = styled(Link)`
  font-family: 'Lato';
  font-size: 1.1875em;
  text-decoration: none;
  color: ${({ active, theme }) =>
    active ? theme.colors.primary.blue : theme.colors.primary.black};
  border-top: ${({ active, theme }) =>
    active && `3px solid ${theme.colors.primary.blue}`};
  font-weight: ${({ active }) => active && 800};
  padding: 0.35em 0.4em;
  margin-bottom: 0.5em;
`
