import React, { useEffect } from 'react'
import Glide from '@glidejs/glide'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

import mediaItems from "config/media_items"

import Title from "./base/Title"
import MediaItem from "./MediaItem"
import {
  MediaContainer,
  GlideContainer,
  Glide as GlideObject,
  GlideTrack,
  GlideSlides,
  GlideSlide,
  GlideArrows,
  GlideArrowLeft,
  GlideArrowRight
} from "./Media.styled"

const Media = () => {
  useEffect(() => {
    new Glide('.glide', {
      type: 'carousel',
      autoplay: 3000,
      startAt: 0,
      perView: 4,
      gap: 0,
      breakpoints: {
        3440: { perView: 7 },
        2660: { perView: 5 },
        1920: { perView: 4 },
        1500: { perView: 3 },
        1200: { perView: 2 },
        900: { perView: 1, gap: 20}
      }
    }).mount()
  }, [])
  
  return (
    <MediaContainer id="media">
      <Title as="h2">Media & Resources</Title>
      <GlideObject>
        <GlideTrack>
          <GlideSlides>
            {
              mediaItems.map((item, i) =>
                <GlideSlide key={i}>
                  <MediaItem item={item} />
                </GlideSlide>
              )
            }
          </GlideSlides>
        </GlideTrack>
        <GlideArrows>
          <GlideArrowLeft><FontAwesomeIcon icon={faChevronLeft}/></GlideArrowLeft>
          <GlideArrowRight><FontAwesomeIcon icon={faChevronRight}/></GlideArrowRight>
        </GlideArrows>
      </GlideObject>
    </MediaContainer>
  )
}

export default Media
