import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from "./base/Title"
import Paragraph from "./base/Paragraph"
import {
  AboutContainer,
  AboutContent,
  AboutImageContainer
} from './About.styled'

const About = () => {
  const breakpoints = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "About.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <AboutContainer id="about">
      <AboutImageContainer>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          style={{
            height: `${breakpoints.md ? "300px" : "100%"}`,
            borderRadius: `0px ${breakpoints.md ? "0px" : "167px"} 0px 0px`
          }}
        />
      </AboutImageContainer>
      <AboutContent>
        <Title as="h2">About GHA</Title>
        <Paragraph>
          Global Homestay Alliance (GHA) members have
          been advocating for international student
          support, standards and quality experiences
          for more than a decade. We encourage diversity
          and actively promote the shared cultural exchange
          offered by homestay.
        </Paragraph>
        <Paragraph>
          Our members operate using world-class standards,
          technology and best practices that are employed
          in every market, with every placement.  GHA is
          dedicated to student wellbeing, focussing on
          selecting hosts and developing programs that foster
          positive mental health and create local support
          networks for international students. 
        </Paragraph>
        <Paragraph>
          Our services include welcoming short and long term
          homestays, tailored programs for high school
          students/under 18s, study tours and camps as well as
          independent student accommodation - all facilitated by
          our local teams.
        </Paragraph>
        <Paragraph>
          GHA has partnered with thousands of education providers,
          agents and industry bodies to maximise the student
          experience in Australia, Canada and the USA. These
          connections have inspired us to establish new solutions
          to respond to demand for safe, welcoming and affordable
          accommodation for international students.
        </Paragraph>
      </AboutContent>
    </AboutContainer>
  )
}

export default About
