import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { slide as Menu } from 'react-burger-menu'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import NavigationItem from './NavigationItem'
import NavigationMobileItem from './NavigationMobileItem'
import mobileStyles from "./NavigationMobileStyles"
import {
  NavigationContainer,
  NavigationLogoContainer,
  NavigationLinksContainer
} from './Navigation.styled'

const Navigation = () => {
  const [location, setLocation] = useState("/#home")
  const breakpoints = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fixed(width: 250, height: 97) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const checkForScrollEvent = event => 
    setLocation(`/#${event.detail.anchor.id}`)

  useEffect(() => {
    document.addEventListener('scrollStart', checkForScrollEvent, false); 
  }, [])

  return (
    <NavigationContainer>
      <NavigationLogoContainer>
        <Img fixed={data.placeholderImage.childImageSharp.fixed} /> 
      </NavigationLogoContainer>
      <NavigationLinksContainer>
        {
          !breakpoints.md
            ?
              <>
                <NavigationItem
                  to="/#home"
                  location={location}
                >
                  Home
                </NavigationItem>
                <NavigationItem
                  to="/#about"
                  location={location}
                >
                  About GHA
                </NavigationItem>
                <NavigationItem
                  to="/#members"
                  location={location}
                >
                  Members
                </NavigationItem>
                <NavigationItem
                  to="/#initiatives"
                  location={location}
                >
                  Initiatives
                </NavigationItem>
                <NavigationItem
                  to="/#media"
                  location={location}
                >
                  Media & Resources
                </NavigationItem>
                <NavigationItem
                  to="/#inquiries"
                  location={location}
                >
                  Inquiries
                </NavigationItem>
              </>
            :
              <Menu styles={mobileStyles} right>
                <NavigationMobileItem to="/#home">Home</NavigationMobileItem>
                <NavigationMobileItem to="/#about">About GHA</NavigationMobileItem>
                <NavigationMobileItem to="/#members">Members</NavigationMobileItem>
                <NavigationMobileItem to="/#initiatives">Initiatives</NavigationMobileItem>
                <NavigationMobileItem to="/#media">Media & Resources</NavigationMobileItem>
                <NavigationMobileItem to="/#inquiries">Inquiries</NavigationMobileItem>
              </Menu>
        }
      </NavigationLinksContainer>
    </NavigationContainer>
  )
}

export default Navigation
