import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Title from "./base/Title"
import Paragraph from "./base/Paragraph"
import {
  MembersContainer,
  MembersContent,
  MembersContentLogo,
  MembersContentText,
  MembersSeparator
} from "./Members.styled"

const Members = () => {
  const data = useStaticQuery(graphql`
    query {
      ahn: file(relativePath: { eq: "AHN.png" }) {
        childImageSharp {
          fixed(width: 277, height: 124) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ahn_usa: file(relativePath: { eq: "AHN_USA.png" }) {
        childImageSharp {
          fixed(width: 330, height: 92) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ame: file(relativePath: { eq: "AME.png" }) {
        childImageSharp {
          fixed(width: 326, height: 59) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chn: file(relativePath: { eq: "CHN.png" }) {
        childImageSharp {
          fixed(width: 221, height: 148) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      srs: file(relativePath: { eq: "SRS.png" }) {
        childImageSharp {
          fixed(width: 230, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      srsc: file(relativePath: { eq: "SRSC.png" }) {
        childImageSharp {
          fixed(width: 290, height: 69) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nzh: file(relativePath: { eq: "NZH.jpg" }) {
        childImageSharp {
          fixed(width: 290, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <MembersContainer  id="members">
      <Title as="h2">Members</Title>
      <MembersContent>
        <MembersContentLogo>
          <a href="https://www.homestaynetwork.org/">
            <Img fixed={data.ahn.childImageSharp.fixed} />
          </a>
        </MembersContentLogo>
        <MembersContentText>
          <Paragraph>
            The&nbsp;
            <a href="https://www.homestaynetwork.org/">Australian Homestay Network</a>
            &nbsp;(AHN) is Australia's largest and most recognised international student
            homestay provider. Our core purpose is to facilitate lifetime relationships
            and be the leader in properly-managed hosted accommodation based on the
            principles of accountability, integrity, transparency, personalised services,
            safety, and 24/7 professional support. 
          </Paragraph>
          <Paragraph>
            AHN has been acknowledged by the government in Australia for bringing standards to
            the international student homestay industry. Since 2008, we have successfully
            placed more than 63,000 guests from 183 countries in welcoming homes across
            Australia. Our dedicated teams service every capital city in the country along
            with other key regional student destinations.
          </Paragraph>
        </MembersContentText>
      </MembersContent>
      <MembersSeparator />
      <MembersContent>
        <MembersContentLogo>
          <a href="https://canadahomestaynetwork.ca/">
            <Img fixed={data.chn.childImageSharp.fixed} />
          </a>
        </MembersContentLogo>
        <MembersContentText>
          <Paragraph>
            The <a href="https://canadahomestaynetwork.ca/">Canada Homestay Network (CHN)</a>
            &nbsp;is the leading homestay program management firm in Canada,
            having served tens of thousands of satisfied students, from coast 
            to coast. Our best practices and due diligence help our students to enjoy
            every aspect of their experience in Canada. 
          </Paragraph>
          <Paragraph>
            Our vision is a more understanding, tolerant and peaceful
            world through openness, education and travel. With our work
            being its own reward, the outcomes are no surprise: long
            term relationships based on mutual trust with outstanding
            clients in the public and private sectors, across Canada
            and overseas. 
          </Paragraph>
          <Paragraph>
            We express our vision and values in every aspect of homestay
            program management: listen curiously, collaborate optimistically,
            guide compassionately, grow courageously, and give wholeheartedly.
            Our stewardship of the international student homestay program
            ensures that homestay is a seamless part of the overall student
            experience. 
          </Paragraph>
          <Paragraph>
            Since 1995, CHN has helped more than 80,000 students from 140
            countries find a home away from home in Canada. CHN follows the
            accreditation standards of Languages Canada
            (<a href="https://www.languagescanada.ca/en/">https://www.languagescanada.ca/en/</a>)
            &nbsp;and the Canadian Association
            of Public Schools - International (CAPSI&nbsp;
            <a href="https://caps-i.ca">https://caps-i.ca</a>)
            and is a regular sponsor of Languages Canada, CAPS-I, and CBIE.  
          </Paragraph>
        </MembersContentText>
      </MembersContent>
      <MembersSeparator />
      <MembersContent>
        <MembersContentLogo>
          <a href="https://newzealandhomestay.co.nz/">
            <Img fixed={data.nzh.childImageSharp.fixed} />
          </a>
        </MembersContentLogo>
        <MembersContentText>
          <Paragraph>
            <a href="https://newzealandhomestay.co.nz/">New Zealand Homestay</a> (NZH) has introduced a nationwide,
            standards-based hosted accommodation service to the Kiwi market.
          </Paragraph>
          <Paragraph>
            Founded on the successful model of the Australian Homestay Network, NZH delivers a range of homestay
            options for under 18 and over 18 international students learning in New Zealand as well as visiting
            study tour groups.
          </Paragraph>
          <Paragraph>
            The brand launched in 2023 in key destinations across the North and South Islands to provide an enriching,
            secure and memorable cultural experience for students and hosts.
          </Paragraph>
        </MembersContentText>
      </MembersContent>
      <MembersSeparator />
      <MembersContent>
        <MembersContentLogo>
          <a href="https://studentroomstay.com/">
            <Img fixed={data.srs.childImageSharp.fixed} />
          </a>
        </MembersContentLogo>
        <MembersContentText>
          <Paragraph>
            Launched in 2020, <a href="https://studentroomstay.com/">StudentRoomStay.com</a> (SRS)
            is building the future of student accommodation worldwide through an online
            booking app and system to provide an all-inclusive, one-stop-shop for student
            housing and student life.
          </Paragraph>
          <Paragraph>
            Serving international and domestic students of all ages, SRS offers a safe and user
            friendly platform to book a range of off-campus housing and homestays with
            matching services to help students find the perfect place and 24/7 support to
            ensure a positive academic experience in the United States.
          </Paragraph>
        </MembersContentText>
      </MembersContent>
    </MembersContainer>
  )
}

export default Members
