import React from "react"

import Paragraph from "./base/Paragraph"
import Button from "./base/Button"
import {
  MediaItemContainer, MediaItemButtonContainer
} from "./Media.styled"

const MediaItem = ({ item }) => (
  <MediaItemContainer>
    <img alt="" src={item.cover} />
    <Paragraph mt="1em"><strong>{item.date}</strong></Paragraph>
    <Paragraph mt="0.5em">{item.title}</Paragraph>
    <Paragraph mt="0.275em"><em>{item.sub}</em></Paragraph>
    <MediaItemButtonContainer>
      <Button as="a" href={item.article}>View More</Button>
    </MediaItemButtonContainer>
  </MediaItemContainer>
)

export default MediaItem
