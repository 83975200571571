import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from './base/Title'
import Separator from './base/Separator'
import {
  HomeMembersContainer,
  HomeMembersLogos,
  HomeMembersLogo
} from './Home.styled'

const HomeMembers = ({ isMobile }) => {
  const data = useStaticQuery(graphql`
    query {
      ahn: file(relativePath: { eq: "AHN.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ahn_usa: file(relativePath: { eq: "AHN_USA.png" }) {
        childImageSharp {
          fluid(maxWidth: 252) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ame: file(relativePath: { eq: "AME.png" }) {
        childImageSharp {
          fluid(maxWidth: 314) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chn: file(relativePath: { eq: "CHN.png" }) {
        childImageSharp {
          fluid(maxWidth: 162) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      srs: file(relativePath: { eq: "SRS.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      srsc: file(relativePath: { eq: "SRSC.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nzh: file(relativePath: { eq: "NZH.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HomeMembersContainer>
      <Title as="h2" size="2em" mb="0.2em" black>GHA Members</Title> 
      <Separator width={ isMobile ? "80%" : "446px" } mb="2em" />
      <HomeMembersLogos>
        <HomeMembersLogo width="200px">
          <a href="https://www.homestaynetwork.org/">
            <Img fluid={data.ahn.childImageSharp.fluid} />
          </a>
        </HomeMembersLogo>
        <HomeMembersLogo width="162px">
          <a href="https://canadahomestaynetwork.ca/">
            <Img fluid={data.chn.childImageSharp.fluid} />
          </a>
        </HomeMembersLogo>
        <HomeMembersLogo width="250px">
          <a href="https://newzealandhomestay.co.nz/">
            <Img fluid={data.nzh.childImageSharp.fluid} />
          </a>
        </HomeMembersLogo>
        <HomeMembersLogo width="189px">
          <a href="https://studentroomstay.com/">
            <Img fluid={data.srs.childImageSharp.fluid} />
          </a>
        </HomeMembersLogo>
      </HomeMembersLogos>
    </HomeMembersContainer>
  )
}

export default HomeMembers
