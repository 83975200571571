import React from "react"

import Home from "../components/Home"
import About from "../components/About"
import Layout from "../components/Layout"
import Members from "../components/Members"
import Initiatives from "../components/Initiatives"
import Media from "../components/Media"
import Contact from "../components/Contact"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
    <About />
    <Members />
    <Initiatives />
    <Media />
    <Contact />
  </Layout>
)

export default IndexPage
