import React from 'react'

import { NavigationLink } from './Navigation.styled' 

const NavigationItem = ({ to, children, location }) => {
  const isActive = location => location === to ? "true" : undefined

  return (
    <NavigationLink
      to={to}
      active={isActive(location)}
    >
      { children }
    </NavigationLink>
  )
}

export default NavigationItem
