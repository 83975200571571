import styled from 'styled-components'

const Paragraph = styled.p`
  font-size: 1.125em;
  color: ${({ theme }) => theme.colors.primary.black};
  letter-spacing: 0.05em;
  line-height: 1.4em;
  &:not(:last-child) {
    margin-bottom: 1.2em;
  }
  margin-top: 0em;
  && {
    margin-top: ${({ mt }) => mt && mt};
    margin-bottom: ${({ mb }) => mb && mb};
  }
`

export default Paragraph
