import styled from "styled-components"

export const InitiativesContainer = styled.div`
  display: flex;
  @media(max-width: 1024px) {
    flex-direction: column-reverse;
  }

`

export const InitiativesContent = styled.div`
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex: 1 1 auto;
  @media(max-width: 1024px) {
    max-width: 100%;
    text-align: center;
  }
`

export const InitiativesImageContainer = styled.div`
  flex: 1 1 auto;
  max-width: 40%;
  @media(max-width: 1024px) {
    max-width: 100%;
  }

`
