import styled from 'styled-components'

const Title = styled.h1`
  color: ${({ black, theme }) =>
    black ? theme.colors.primary.black : theme.colors.primary.blue};
  font-size: ${({ size }) => size || "2.8125em"};
  text-transform: ${({ capitalize }) =>
    capitalize ? "capitalize" : "uppercase" };
  font-weight: ${({ black }) => black ? 700 : 800};
  letter-spacing: 0.1em;
  line-height: 1.2em;
  margin-top: 0.4em;
  margin-bottom: 0.6em;
  margin-top: ${({ mt }) => mt && mt};
  margin-bottom: ${({ mb }) => mb && mb};
  @media(max-width: 500px) {
    font-size: ${({ size }) => size || "2em"}; 
    text-align: center;
  }
`

export default Title;
