import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Title from './base/Title'
import Paragraph from './base/Paragraph'
import {
  InitiativesContainer,
  InitiativesContent,
  InitiativesImageContainer
} from "./Initiatives.styled"

const Initiatives = () => {
  const breakpoints = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Initiatives.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <InitiativesContainer id="initiatives">
      <InitiativesContent>
        <Title as="h2" mt="1.2em">Latest Initiatives</Title>
        <Title as="h3" size="1.875em" mt="0.4em" mb="0.65em" black capitalize>
            Australian Homestay Standards
        </Title>
        <Paragraph>
          Australia’s first industry-recognised standards for student homestay accommodation providers were announced
          at the 2023 Australian International Education Conference (AEIC) in Adelaide.
        </Paragraph>
        <Paragraph>
          The “Homestay Quality Standards” – independently managed by the National English Language Teaching Accreditation
          Scheme (NEAS) – were developed following a nationwide industry collaboration spearheaded by the NSW Government
          through Study NSW.
        </Paragraph>
        <Paragraph>
          The Australian Homestay Network (AHN) has been advocating for industry-wide homestay standards since it was
          established in 2007 and is proud to be the&nbsp;
          <a href="https://neas.org.au/neas-announces-inaugural-endorsed-homestay-service-provider/" target="_blank">
            first NEAS Endorsed Homestay Service
          </a>.
        </Paragraph>
        <Paragraph>
          The official standards and quality assurance framework not only provides credibility to the sector, but also helps
          students and potential hosts identify trustworthy homestay companies to engage with.
        </Paragraph>
        <Title as="h3" size="1.875em" mt="0.4em" mb="0.65em" black capitalize>
          Start With Us
        </Title>
        <Paragraph>
          The <em><a href="https://www.homestaynetwork.org/startaustralia/">Start With Us</a></em>
          &nbsp;program offered by the Australian Homestay Network (AHN) has been designed to give students
          the best possible start to their life in a new country. Our
          welcoming hosts offer the feeling and support of home to help
          students settle in as they prepare to begin their studies,
          providing a positive student experience and a local network who
          can assist in future decision making. Students live in an
          approved homestay environment and receive cultural immersion
          through daily interaction and quality time with their host family.
        </Paragraph>
        <Paragraph>
          <em>Start With Us</em> is great value for money including meals,
          utilities and a fully furnished private room with flexibility so
          students can extend their homestay until they are ready to transition
          to share housing or private accommodation.
        </Paragraph>
        <Title as="h3" size="1.875em" mt="0.4em" mb="0.65em" black capitalize>
          Disability Homestay Network
        </Title>
        <Paragraph>
          The <a href="https://disabilityhomestay.com/">Disability Homestay Network (DHN)</a>
          &nbsp;program was designed to improve the quality and quantity of short-term
          accommodation options in Australia for people with disability. 
        </Paragraph>
        <Paragraph>
          The initiative uses the infrastructure and experience of the successful homestay
          model operated by AHN to provide properly-managed, standards-based hosted
          accommodation for eligible participants funded through the National Disability
          Insurance Scheme (NDIS).
        </Paragraph>
        <Paragraph>
          Better access to appropriate accommodation and supports empowers people with
          disability to have more choice, more flexibility in their funding, and greater
          control on their journey to achieving their goals. 
        </Paragraph>
      </InitiativesContent>
      <InitiativesImageContainer>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          style={{
            height: `${breakpoints.md ? "300px" : "100%"}`,
            borderRadius: `${breakpoints.md ? "0px" : "167px"} 0px 0px 0px`
          }}
        />
      </InitiativesImageContainer>
    </InitiativesContainer>
  )
}

export default Initiatives
