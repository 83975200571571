import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from "./base/Title"
import Paragraph from "./base/Paragraph"
import Span from "./base/Span"
import {
  ContactContainer,
  ContactContent,
  ContactImageContainer
} from './Contact.styled'

const About = () => {
  const breakpoints = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContactContainer id="inquiries">
      <ContactImageContainer>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          style={{
            height: `${breakpoints.md ? "300px" : "100%"}`,
            borderRadius: `0px ${breakpoints.md ? "0px" : "167px"} 0px 0px`
          }}
        />
      </ContactImageContainer>
      <ContactContent>
        <Title as="h2">Inquiries</Title>
        <Paragraph>
          The Global Homestay Alliance and our Members will work
          with you to create a positive international student
          experience. Get in touch today to find out how we can
          help you!
        </Paragraph>
        <Paragraph mb="0.05em">
          <strong>Global Homestay Alliance</strong>
        </Paragraph>
        <Paragraph mt="0.25em">
          Email: <a href="mailto:info@globalhomestaynetwork.com">info@globalhomestaynetwork.com</a>
          <br />
          Phone: <a href="tel:+0268811936">(02) 6881 1936</a>
        </Paragraph>
        <Paragraph mb="0.05em">
          <strong>Member Contacts</strong>
        </Paragraph>
        <Paragraph mt="0.25em">
          <a href="https://www.homestaynetwork.org/contact-us/">Homestay Australia</a>
          <br />
          <a href="http://canadahomestaynetwork.ca/contact/">Homestay Canada</a>
          <br />
          <a href="https://studentroomstay.com/contact">StudentRoomStay</a>
        </Paragraph>
      </ContactContent>
    </ContactContainer>
  )
}

export default About
