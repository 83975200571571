import styled from 'styled-components'

const Separator = styled.hr`
  border: 0px;
  border-top: 1px solid #E6E6E6;
  width: ${({ width }) => width || '100%'};
  margin-top: ${({ mt }) => mt && mt};
  margin-bottom: ${({ mb }) => mb && mb};
`

export default Separator
