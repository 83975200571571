import React, { Fragment }from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from './base/Title'
import Paragraph from './base/Paragraph'
import Separator from './base/Separator'
import Button from './base/Button'
import HomeMembers from './HomeMembers'
import {
  HomeContainer,
  HomeContent,
  HomeImageContainer,
  HomeButtons
} from './Home.styled'

const Home = () => {
  const breakpoints = useBreakpoint()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <HomeContainer id="home">
        <HomeContent>
          <Title>Delivering superior student accommodation experiences</Title>
          <Paragraph>
            The Global Homestay Alliance (GHA) was established in 2019
            to bring together the world's best standards-based student
            homestay and accommodation providers representing Australia,
            Canada and the USA.
          </Paragraph>
          <Paragraph>
            Our Group shares a commitment to global industry standards
            and advocates for student wellbeing in all aspects of the
            study journey.  GHA supports industry engagement and is focused
            on delivering the highest quality student experience based on
            personalised services, safety, insurance, 24/7 professional
            support, accountability, transparency and system integrity. 
          </Paragraph>
          <Separator mt="1em" mb="1.7em" />
          <HomeButtons>
            <Button to="/#about">About GHA</Button>
            <Button to="/#members">Members</Button>
            <Button to="/#initiatives">Initiatives</Button>
            <Button to="/#media">Media & Resources</Button>
          </HomeButtons>
        </HomeContent>
        <HomeImageContainer>
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{
              height: `${breakpoints.md ? "300px" : "100%"}`,
              borderRadius: `0px 0px 0px ${breakpoints.md ? "0px" : "167px"}`
            }}
          />
        </HomeImageContainer>
      </HomeContainer>
      <HomeMembers isMobile={breakpoints.md} />
    </Fragment>
  )
}

export default Home
