import styled from 'styled-components'
import Button from './base/Button'

export const HomeContainer = styled.div`
  display: flex;
  @media(max-width: 1023px) {
    flex-direction: column-reverse;
  }
`

export const HomeContent = styled.div`
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex: 1 1 auto;
  margin-bottom: 4em;
  @media(max-width: 1023px) {
    max-width: 100%;
    text-align: center;
  }
`

export const HomeMembersContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4em;
  flex-direction: column;
  align-items: center;
`

export const HomeMembersLogos = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media(max-width: 1350px) {
    flex-wrap: wrap;
  }
`

export const HomeMembersLogo = styled.div`
  width: ${({ width }) => width && width};
  max-width: 100%;
  @media(max-width: 1350px) {
    margin-bottom: 2em
  }
  &:not(:last-child) {
   margin-right: 2.25em;
  }
`

export const HomeButtons = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  @media(max-width: 1023px) {
    justify-content: center;
  }
  & ${Button} {
    @media(max-width: 1300px) {
      margin-bottom: 1em;
    }
  }
`

export const HomeImageContainer = styled.div`
  flex: 1 1 auto;
  max-width: 45%;
  @media(max-width: 1023px) {
    max-width: 100%;
    max-height: 300px;
  }
`
