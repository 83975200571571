const MediaItems = [
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/all-about-ahn-with-david-bycroft_dpxgrm.jpg",
    date: "25th July 2022",
    title: "All About Australian Homestay Network with David Bycroft",
    sub: "Now Boarding by Melting Pot Podcast",
    article: "https://open.spotify.com/episode/0dr12VirhslSJsBABym4iF",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1664842661/ghn-marketing-site/host-survey-findings-canada-homestay-network_cqqgsr.jpg",
    date: "21st July 2022",
    title: "Host Survey Findings, Canada Homestay Network",
    sub: "StudyTravel News",
    article: "https://studytravel.network/magazine/news/2/29197 ",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/obodo-makes-strategic-investment-into-srs_svfyzq.jpg",
    date: "15th July 2022",
    title: "Obodo Makes Strategic Investment into StudentRoomStay",
    sub: "Press Release",
    article: "https://blog.studentroomstay.com/obodo-makes-strategic-investment-into-studentroomstay",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/canada-homestay-hosts-payments-raised_uxupgf.jpg",
    date: "6th July 2022",
    title: "Homestay Hosts’ Compensation Raised in Canada",
    sub: "The PIE",
    article: "https://thepienews.com/news/canada-homestay-hosts-payments-raised/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/joshua-finds-his-voice_clim5k.jpg",
    date: "28th June 2022",
    title: "Joshua Finds His Voice",
    sub: "Press Release",
    article: "https://www.medianet.com.au/news-hub-post/974138",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/how-to-find-legitimate-accommodation-at-university_zpe5pq.jpg",
    date: "24th June 2022",
    title: "How to Find Legitimate Accommodation at University",
    sub: "THE Student",
    article: "https://www.timeshighereducation.com/student/advice/how-find-legitimate-accommodation-university",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1659578829/ghn-marketing-site/industry-leaders-collaborate-to-create-pathway-to-success-for-young-international-students_r9fgr6.jpg",
    date: "17th May 2022",
    title: "Industry Leaders Collaborate to Create Pathway to Success for Young International Student",
    sub: "Press Release",
    article: "https://www.homestaynetwork.org/wp-content/uploads/2022/05/AHN-AEAS-ISA-Partnership-Media-Release.pdf",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1650337266/ghn-marketing-site/InternationalStudent-Announces-_Partnership-With-SRS_r7thth.png",
    date: "14th April 2022",
    title: "InternationalStudent.com Announces Partnership With StudentRoomStay",
    sub: "PRWeb",
    article: "https://www.prweb.com/releases/2022/4/prweb18615576.htm",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/International-Students-Seek-Accommodation-as-Gold-Coast-Housing-Crisis-Worsens_x8qvis.jpg",
    date: "9th February 2022",
    title: "International Students Seek Accommodation as Gold Coast Housing Crisis Worsens",
    sub: "ABC Gold Coast",
    article: "https://www.abc.net.au/news/2022-02-09/gold-coast-purpose-built-student-accommodation-housing-crisis/100815576",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308158/ghn-marketing-site/Accommodation-Options-for-International-Students_wz7uhn.jpg",
    date: "30th January 2022",
    title: "Accommodation Options for International Students",
    sub: "The Koala Club Podcast",
    article: "https://www.listennotes.com/podcasts/the-koala-club/accommodation-options-for-A3DIjKiu0WT/#episode"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308158/ghn-marketing-site/DHN-to-Provide-Hosted-Accommodation-Options-in-Australia_phzyde.jpg",
    date: "19th January 2022",
    title: "DHN to Provide Hosted Accommodation Options in Australia",
    sub: "Disability Insider",
    article: "https://disabilityinsider.com/2022/01/19/misc/dhn-to-provide-hosted-accommodation-options-in-australia/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308160/ghn-marketing-site/StudentRoomStay-Partnership-with-Think-X_vinvx7.jpg",
    date: "7th December 2021",
    title: "Students to Gain Significant Career Advantage Through StudentRoomStay Partnership with Think-X",
    sub: "Press Release",
    article: "https://blog.studentroomstay.com/studentroomstay_partners_with_think-x"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647315650/ghn-marketing-site/Students-Find-Homes-with-Nova-Scotia-Families-Cathy-Targett-Image_c6sqs2.jpg",
    date: "23rd November 2021",
    title: "Students Find Homes with Nova Scotia Families",
    sub: "The Signal",
    article: "https://signalhfx.ca/students-find-homes-with-nova-scotia-families/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/New-Accommodation-Program-Opens-the-Door-for-NDIS-Participants_ft7pek.jpg",
    date: "19th November 2021",
    title: "New Accommodation Program Opens the Door for NDIS Participants",
    sub: "Press Release",
    article: "https://www.medianet.com.au/news-hub-post?id=961820"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/On-a-Mission-Australian-Homestay-Network_wrzse0.jpg",
    date: "15th November 2021",
    title: "'On a Mission: Australian Homestay Network' featuring David Bycroft (AHN)",
    sub: "Ultra 106.5fm / WAY FM Radio",
    article: "https://ultra106five.com/mission-australian-homestay-network/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/New-Virtual-Homestay-ENGLISH-Program-to-Connect-the-World-with-Australian-Hosts_nut4vw.jpg",
    date: "8th September 2021",
    title: "New Virtual Homestay ENGLISH Program to Connect the World with Australian Hosts",
    sub: "Press Release",
    article: "https://www.medianet.com.au/news-hub-post?id=206858"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/MSI-Announces-Capital-Raise-for-Australians_qyel8b.jpg",
    date: "22nd June 2021",
    title: "MyStay International Announces Capital Raise for Australians to 'Unite, Recover and Invest for the Future'",
    sub: "Press Release",
    article: "https://www.medianet.com.au/news-hub-post?id=953008"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/AHN-ISSN-4ZZZ_TalkerSpace-_Program-Radio-Interview_cdzg2w.jpg",
    date: "16th September 2020",
    title: "Radio Interview on 4ZZZ TalkerSpace Program featuring David Bycroft (AHN)",
    sub: "4ZZZ",
    article: "https://www.homestaynetwork.org/wp-content/uploads/2020/09/4ZZZ-David-Bycroft-Sep2020.mp3"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/Hundreds-of-International_Students-Find-Support-in-Australian-Homes_nv22wi.jpg",
    date: "11th September 2020",
    title: "Hundreds of International Students Find Support in Australian Homes",
    sub: "The PIE",
    article: "https://thepienews.com/news/hundreds-of-intl-students-find-support-in-aus-homes/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/Crisis-Cover-Partners-with-_StudentRoomStay_brbdhk.jpg",
    date: "3rd July 2020",
    title: "Crisis Cover partners with StudentRoomStay",
    sub: "The PIE",
    article: "https://thepienews.com/news/crisis-cover-partners-with-studentroomstay/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1594005672/ghn-marketing-site/New-Alliance-to-Deliver-Superior-Student-Accommodation-Experiences-Across-the-Globe_pw3lgh.jpg",
    date: "2nd July 2020",
    title: "New Alliance to Deliver Superior Student Accommodation Experiences Across the Globe",
    sub: "Press Release",
    article: "https://www.medianet.com.au/releases/188911/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308160/ghn-marketing-site/StudentRoomStay-Joins-Industry-Leaders-to-Form-Global-Homestay-_Alliance_vbupoj.jpg",
    date: "1st July 2020",
    title: "StudentRoomStay Joins Industry Leaders to Form the Global Homestay Alliance",
    sub: "Press Release",
    article: "https://blog.studentroomstay.com/global-homestay-alliance"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308159/ghn-marketing-site/StudentRoomStay-Announces-10m-Funding-Round_yujv3w.jpg",
    date: "12th May 2020",
    title: "StudentRoomStay Announces $10m Funding Round",
    sub: "The PIE",
    article: "https://thepienews.com/news/studentroomstay-set-to-launch-with-10m-funding-round/"
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1590546477/ghn-marketing-site/media-10_fzbu8j.jpg",
    date: "9th May 2020",
    title: "Australians sign up to offer homes to stranded international students - Stefan Armbruster",
    sub: "SBS World News",
    article: "https://www.homestaynetwork.org/wp-content/uploads/2020/05/ISSN-on-SBS-World-News-9-May-2020.mp4",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1590546478/ghn-marketing-site/media-11_po4mnu.jpg",
    date: "5th May 2020",
    title: "StudentRoomStay.com Takes Off During Downturn",
    sub: "Press Release",
    article: "http://medianet.com.au/releases/186936/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1587534810/ghn-marketing-site/media-3_bf0svr.png",
    date: "20th April 2020",
    title: "Industry Chat - David Bycroft - Founder of Australian Homestay Network",
    sub: "Transcribed Interview with Insider Guides",
    article: "https://www.voiceintellect.com.au/Projects/Play/1582",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1587534810/ghn-marketing-site/media-2_e76dtp.png",
    date: "17th April 2020",
    title: "Industry Chat – David Bycroft – Founder of Australian Homestay Network",
    sub: "Interview with Insider Guides",
    article: "https://insiderguides.com.au/industry-chat-david-bycroft-founder-of-australian-homestay-network/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1587534810/ghn-marketing-site/media-1_vdtfhl.png",
    date: "16th April 2020",
    title: "Australia’s Community of Kindness to Help International Students in Need",
    sub: "Press Release",
    article: "https://www.medianet.com.au/releases/186396/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1590453464/ghn-marketing-site/media-4_mju80d.jpg",
    date: "1st April 2020",
    title: "Australian Homestay Network Supporting International Students and Industry",
    sub: "Press Release",
    article: "https://www.medianet.com.au/releases/185941/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1591054899/ghn-marketing-site/media-5_bcbcwk.jpg",
    date: "13th February 2020",
    title: "Australian Hosts Respond Positively to coronavirus Situation",
    sub: "Press Release",
    article: "https://www.medianet.com.au/releases/184263/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1590453464/ghn-marketing-site/media-6_frkt3i.jpg",
    date: "13th February 2020",
    title: "Education Industry Works Together During the Coronavirus Situation",
    sub: "Press Release",
    article: "https://www.medianet.com.au/releases/184264/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1587970518/ghn-marketing-site/media-7_lzmo9c.jpg",
    date: "23rd December 2019",
    title: "New Australian Homestay Network campaign to help struggling students",
    sub: "The PIE",
    article: "https://thepienews.com/news/australian-homestay-network-launches-campaign-to-help-students/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1590453464/ghn-marketing-site/media-8_xj1vnk.jpg",
    date: "23rd September 2019",
    title: "Part 1 - Homestay Expertise and Building Relationships with Jennifer Wilson from the Canada Homestay Network",
    sub: "Press Release",
    article: "http://homestaykitchen.com/2019/09/episode-35-part-1-homestay-expertise-and-building-relationships-with-jennifer-wilson-from-the-canada-homestay-network/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1591054899/ghn-marketing-site/media-9_e6oj7q.jpg",
    date: "23rd September 2019",
    title: "Part 2 - Homestay Expertise and Building Relationships with Jennifer Wilson from the Canada Homestay Network",
    sub: "Press Release",
    article: "http://homestaykitchen.com/2019/09/episode-36-part-2-sending-your-kids-to-study-abroad-and-the-homestay-industry-with-jennifer-wilson-from-the-canada-homestay-network/",
  },
  {
    cover: "https://res.cloudinary.com/dhct7cobp/image/upload/v1647308160/ghn-marketing-site/University-Homestay-Programs-Strive-to-Offer-More-Than-Room-and-Bboard_tmqqsj.jpg",
    date: "21st August 2019",
    title: "University Homestay Programs Strive to Offer More Than Room and Board",
    sub: "University Affairs",
    article: "https://www.universityaffairs.ca/features/feature-article/university-homestay-programs-strive-to-offer-more-than-room-and-board/"
  }
]

export default MediaItems
