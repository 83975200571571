import styled from 'styled-components'
import { Link } from 'gatsby'

const Button = styled(Link)`
  padding: 0.72em 1.5em;
  font-weight: bold;
  line-height: 1.2em;
  background: ${({ theme }) => theme.colors.primary.blue};
  color: white;
  text-decoration: none;
  border-radius: 99px;
  font-size: 1.125em;
  &:not(:last-child) {
    margin-right: 0.75em;
  }
` 

export default Button
