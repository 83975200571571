import styled from "styled-components"


export const MembersContainer = styled.div`
  display: flex;
  margin-top: 5.9375em;
  margin-bottom: 5.9375em;
  flex-direction: column;
  align-items: center;
`

export const MembersContent = styled.div`
  margin: 1em 9.5em;
  display: flex;
  @media(max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 1em 4em;
  }
`

export const MembersContentLogo = styled.div`
  min-width: 420px;
  display: flex;
  align-items: center;
  @media(max-width: 1023px) {
    min-width: inherit;
    justify-content: center;
    margin-bottom: 2.5em;
  }
`

export const MembersContentText = styled.div`
  display: flex;
  flex-direction: column;
`

export const MembersSeparator = styled.hr`
  border: 0px;
  border-top: 2px solid #6FA1D4;
  width: 74px;
  margin: 3.125em auto;
`
