import React from 'react'
import { Helmet } from 'react-helmet'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme from 'config/theme'
import Navigation from './Navigation'

import 'normalize.css/normalize.css'

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    offset: (anchor, toggle) => ({
      "about": 130,
      "membership": 200 
    })[anchor.id] || 117
  })
} 
 

const GlobalStyles = createGlobalStyle`
  body {
    @media (min-width: 1900px) {
      font-size: 20px;
    }
    @media(max-width: 1900px) {
      font-size: 18px;
    }
    @media(max-width: 1600px) {
      font-size: 16px;
    }
    @media(max-width: 1400px) {
      font-size: 15px;
    }
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3 {
    font-family: 'Lato', sans-serif; 
  }

  p, a, strong {
    font-family: 'Open Sans', sans-serif;
  }

  a {
    text-decoration: none;
    color: #6FA1D4;
  }
`

const Header = styled.header`
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Open+Sans:wght@400;700&family=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Header>
      <Navigation />
    </Header>
    <main>
      { children }
    </main>
  </ThemeProvider>
)

export default Layout
