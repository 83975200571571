import React from 'react'
import { Location } from "@reach/router"

import { NavigationMobileLink } from './Navigation.styled' 

const NavigationItem = ({ to, children }) => {
  const isActive = (location) => {
    return location.hash === to ? "true" : undefined
  }

  return (
    <Location>
      {
        ({ location }) =>
          <NavigationMobileLink
            getProps={isActive}
            to={to}
            active={isActive(location)}
            className="menu-item"
          >
            { children }
          </NavigationMobileLink>
      }
    </Location>
  )
}

export default NavigationItem
